import React from "react";
import _ from "lodash";

import Project from "./project";

export default class Home extends React.Component {
  render() {
    const firstPage = _.get(this.props, "pageContext.pages").filter(
      page => _.get(page, "frontmatter.order") === 0
    )[0];

    return <Project pageContext={{..._.get(this.props, "pageContext"), ...firstPage}} />;
  }
}
